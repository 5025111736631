/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-12-04T17:33:00
 */
import type { Eventlog, EventlogReadErrorEventsParams } from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve error_events.
 * @summary Read Error Events
 */
export const eventlogReadErrorEvents = (
  params?: EventlogReadErrorEventsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Eventlog[]>(
    { url: `/api/v1/eventlog/error-events`, method: "GET", params },
    options,
  );
};
export type EventlogReadErrorEventsResult = NonNullable<
  Awaited<ReturnType<typeof eventlogReadErrorEvents>>
>;
