/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-12-04T17:33:00
 */
import type {
  BodyPrintersUploadFromEdgeDownloadRequest,
  Printer,
  PrinterCreate,
  PrinterUpdate,
  PrintersReadAllPrintersParams,
  PrintersReadPrintersParams,
  PrintersStatusResponse,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve printers.
 * @summary Read Printers
 */
export const printersReadPrinters = (
  params?: PrintersReadPrintersParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Printer[]>(
    { url: `/api/v1/printers/`, method: "GET", params },
    options,
  );
};
/**
 * Create new printer.
 * @summary Create Printer
 */
export const printersCreatePrinter = (
  printerCreate: PrinterCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Printer>(
    {
      url: `/api/v1/printers/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: printerCreate,
    },
    options,
  );
};
/**
 * Retrieve printers.
 * @summary Read All Printers
 */
export const printersReadAllPrinters = (
  params?: PrintersReadAllPrintersParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Printer[]>(
    { url: `/api/v1/printers/all`, method: "GET", params },
    options,
  );
};
/**
 * Update a printer.
 * @summary Update Printer
 */
export const printersUpdatePrinter = (
  id: number,
  printerUpdate: PrinterUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Printer>(
    {
      url: `/api/v1/printers/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: printerUpdate,
    },
    options,
  );
};
/**
 * Get printer by ID.
 * @summary Read Printer
 */
export const printersReadPrinter = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Printer>(
    { url: `/api/v1/printers/${id}`, method: "GET" },
    options,
  );
};
/**
 * Delete a printer.
 * @summary Delete Printer
 */
export const printersDeletePrinter = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Printer>(
    { url: `/api/v1/printers/${id}`, method: "DELETE" },
    options,
  );
};
/**
 * Get printer by ID.
 * @summary Ping Printer
 */
export const printersPingPrinter = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintersStatusResponse>(
    { url: `/api/v1/printers/ping`, method: "GET" },
    options,
  );
};
/**
 * Reset printer stats.
 * @summary Reset Printer Stats
 */
export const printersResetPrinterStats = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintersStatusResponse>(
    { url: `/api/v1/printers/reset_stats`, method: "GET" },
    options,
  );
};
/**
 * Get printer by ID.
 * @summary Read Printer Print Jobs Count
 */
export const printersReadPrinterPrintJobsCount = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/printers/${id}/print-jobs/count`, method: "GET" },
    options,
  );
};
/**
 * Upload file from edge device for download on clients browser.
 * @summary Upload From Edge Download Request
 */
export const printersUploadFromEdgeDownloadRequest = (
  bodyPrintersUploadFromEdgeDownloadRequest: BodyPrintersUploadFromEdgeDownloadRequest,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("file", bodyPrintersUploadFromEdgeDownloadRequest.file);

  return apiAxiosInstance<PrintersStatusResponse>(
    {
      url: `/api/v1/printers/upload-from-edge/download-request`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
export type PrintersReadPrintersResult = NonNullable<
  Awaited<ReturnType<typeof printersReadPrinters>>
>;
export type PrintersCreatePrinterResult = NonNullable<
  Awaited<ReturnType<typeof printersCreatePrinter>>
>;
export type PrintersReadAllPrintersResult = NonNullable<
  Awaited<ReturnType<typeof printersReadAllPrinters>>
>;
export type PrintersUpdatePrinterResult = NonNullable<
  Awaited<ReturnType<typeof printersUpdatePrinter>>
>;
export type PrintersReadPrinterResult = NonNullable<
  Awaited<ReturnType<typeof printersReadPrinter>>
>;
export type PrintersDeletePrinterResult = NonNullable<
  Awaited<ReturnType<typeof printersDeletePrinter>>
>;
export type PrintersPingPrinterResult = NonNullable<
  Awaited<ReturnType<typeof printersPingPrinter>>
>;
export type PrintersResetPrinterStatsResult = NonNullable<
  Awaited<ReturnType<typeof printersResetPrinterStats>>
>;
export type PrintersReadPrinterPrintJobsCountResult = NonNullable<
  Awaited<ReturnType<typeof printersReadPrinterPrintJobsCount>>
>;
export type PrintersUploadFromEdgeDownloadRequestResult = NonNullable<
  Awaited<ReturnType<typeof printersUploadFromEdgeDownloadRequest>>
>;
