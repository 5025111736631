import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { slugify } from "@/utils";

export const getters = {
  // *** UI ***
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  // *** Users ***
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile &&
      state.userProfile.user_type &&
      state.userProfile.user_type.id == 7 &&
      state.userProfile.is_active
    );
  },
  loginError: (state: MainState) => state.logInError,
  signUpError: (state: MainState) => state.signUpError,
  userProfile: (state: MainState) => state.userProfile,
  userSocket: (state: MainState) => state.userSocket,
  originalURL: (state: MainState) => state.originalURL,
  token: (state: MainState) => state.token,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstAlert: (state: MainState) => state.alerts.length > 0 && state.alerts[0],
  // *** Systems ***
  systems: (state: MainState) => state.systems,
  oneSystem: (state: MainState) => (systemSlug: string) => {
    const filteredSystems = state.systems.filter(
      (system) => slugify(system.name ?? "") === systemSlug,
    );
    if (filteredSystems.length > 0) {
      return { ...filteredSystems[0] };
    }
  },
  oneSystemSocket: (state: MainState) => (systemId: number) => {
    const filteredSystemSockets = state.systemSockets.filter(
      (systemSocket) => systemSocket.id === systemId,
    );
    if (filteredSystemSockets.length > 0) {
      return { ...filteredSystemSockets[0] };
    }
  },
  oneSystemFromId: (state: MainState) => (systemId: number) => {
    const filteredSystems = state.systems.filter((system) => system.id === systemId);
    if (filteredSystems.length > 0) {
      return { ...filteredSystems[0] };
    }
  },
  // *** System jobs ***
  systemJobs: (state: MainState) => state.systemJobs,
  oneSystemJob: (state: MainState) => (systemJobSlug: string) => {
    const filteredPrintJobs = state.systemJobs.filter(
      (job) => slugify(job.long_name || "") === systemJobSlug,
    );
    if (filteredPrintJobs.length > 0) {
      return { ...filteredPrintJobs[0] };
    }
  },
  onePrintJobFromId: (state: MainState) => (systemJobId: number) => {
    const filteredPrintJobs = state.systemJobs.filter((job) => job.id === systemJobId);
    if (filteredPrintJobs.length > 0) {
      return { ...filteredPrintJobs[0] };
    }
  },
  systemJobsFromSystem: (state: MainState) => (systemId: number) => {
    const filteredPrintJobs = state.systemJobs.filter(
      (job) => job.printer_id === systemId,
    );
    if (filteredPrintJobs.length > 0) {
      return filteredPrintJobs;
    }
  },
  errorEventsFromSystemJob: (state: MainState) => (systemJobId: number) => {
    const filteredErrorEvents = state.errorEvents.filter(
      (event) => event.print_job_id === systemJobId,
    );
    return filteredErrorEvents;
  },
  // *** Image items ***
  imageItems: (state: MainState) => state.imageItems,
  imageItemCount: (state: MainState) => state.imageItemCount,
  // *** Files ***
  files: (state: MainState) => state.files,
  oneFile: (state: MainState) => (fileSlug: string) => {
    const filteredFiles = state.files.filter(
      (file) => slugify(file.name ?? "") === fileSlug,
    );
    if (filteredFiles.length > 0) {
      return { ...filteredFiles[0] };
    }
  },
  oneFileFromID: (state: MainState) => (fileId: number) => {
    const filteredFiles = state.files.filter((file) => file.id === fileId);
    if (filteredFiles.length > 0) {
      return { ...filteredFiles[0] };
    }
  },
  // *** Notifications ***
  notifications: (state: MainState) => state.notifications,
  // *** Announcements ***
  announcements: (state: MainState) => state.announcements,
  // *** Products ***
  products: (state: MainState) => state.stripeProducts,
  // *** Pricings ***
  pricings: (state: MainState) => state.stripePricings,
  oneProductPricings: (state: MainState) => (productID: number) => {
    const filteredPricings = state.stripePricings.filter(
      (pricing) => pricing.stripe_product_id === productID,
    );
    if (filteredPricings.length > 0) {
      return { ...filteredPricings[0] };
    }
  },
  // *** Subscriptions ***
  userSubscriptions: (state: MainState) => state.userStripeSubscriptions,
  // *** Macros ***
  macros: (state: MainState) => state.macros,
  uniqueMacros: (state: MainState) => {
    const uniqueMacrosMap = new Map();
    state.macros.forEach((macro) => {
      if (!uniqueMacrosMap.has(macro.id)) {
        uniqueMacrosMap.set(macro.id, macro);
      }
    });
    return Array.from(uniqueMacrosMap.values());
  },
  macroFromId: (state: MainState) => (macroId: number) => {
    const filteredMacros = state.macros.filter((macro) => macro.id === macroId);
    if (filteredMacros.length > 0) {
      return { ...filteredMacros[0] };
    }
  },
  macrosForSystem: (state: MainState) => (systemId: number) => {
    const macrosMap = new Map();
    state.macros
      .filter((macro) => macro.printer_id === systemId)
      .forEach((macro) => {
        if (!macrosMap.has(macro.id)) {
          macrosMap.set(macro.id, macro);
        }
      });
    return Array.from(macrosMap.values());
  },
  // *** Materials ***
  materials: (state: MainState) => state.materials,
  // # region Tags
  tags: (state: MainState) => state.tags,
  tagFromId: (state: MainState) => (tagId: number) => {
    const filteredTags = state.tags.filter((tag) => tag.id === tagId);
    if (filteredTags.length > 0) {
      return { ...filteredTags[0] };
    }
  },
  // # endregion
  // *** Plugins ***
  pluginVersions: (state: MainState) => state.pluginVersions,
  // *** Misc ***
  tasks: (state: MainState) => state.tasks,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
// *** Users ***
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readSignUpError = read(getters.signUpError);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readOriginalURL = read(getters.originalURL);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readUserSocket = read(getters.userSocket);
// *** Alert ***
export const readFirstAlert = read(getters.firstAlert);
export const readNotifications = read(getters.notifications);
export const readAnnouncements = read(getters.announcements);
// *** Systems ***
export const readSystems = read(getters.systems);
export const readOneSystem = read(getters.oneSystem);
export const readOneSystemFromId = read(getters.oneSystemFromId);
export const readOneSystemSocket = read(getters.oneSystemSocket);
// *** Print jobs ***
export const readSystemJobs = read(getters.systemJobs);
export const readOneSystemJob = read(getters.oneSystemJob);
export const readOneSystemJobFromId = read(getters.onePrintJobFromId);
export const readSystemJobsFromSystem = read(getters.systemJobsFromSystem);
// *** Error events ***
export const readErrorEventsFromSystemJob = read(getters.errorEventsFromSystemJob);
// *** Image items ***
export const readImageItems = read(getters.imageItems);
export const readImageItemCount = read(getters.imageItemCount);
// *** Files ***
export const readFiles = read(getters.files);
export const readOneFile = read(getters.oneFile);
export const readOneFileFromId = read(getters.oneFileFromID);
// *** Products ***
export const readProducts = read(getters.products);
// *** Pricings ***
export const readPricings = read(getters.pricings);
// *** Subscriptions ***
export const readUserSubscriptions = read(getters.userSubscriptions);
// *** Macros ***
export const readMacros = read(getters.macros);
export const readUniqueMacros = read(getters.uniqueMacros);
export const readMacroFromId = read(getters.macroFromId);
export const readMacrosForSystem = read(getters.macrosForSystem);
// *** Materials ***
export const readMaterials = read(getters.materials);
// #region Tags
export const readTags = read(getters.tags);
export const readTagFromId = read(getters.tagFromId);
// #endregion
// *** Plugins ***
export const readPluginVersions = read(getters.pluginVersions);
// *** Misc ***
export const readTasks = read(getters.tasks);
