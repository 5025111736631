/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-12-04T17:33:00
 */
import type {
  CreateCustomerPortalSessionRequest,
  CreateCustomerPortalSessionResponse,
  StripeSubscription,
  StripeSubscriptionCreate,
  StripeSubscriptionUpdate,
  StripeWebhookResponse,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve stripe products.
 * @summary Read Stripe Subscriptions
 */
export const subscriptionsReadStripeSubscriptions = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeSubscription[]>(
    { url: `/api/v1/subscriptions/`, method: "GET" },
    options,
  );
};
/**
 * Create new stripe product.
 * @summary Create Stripe Subscription
 */
export const subscriptionsCreateStripeSubscription = (
  stripeSubscriptionCreate: StripeSubscriptionCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeSubscription>(
    {
      url: `/api/v1/subscriptions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: stripeSubscriptionCreate,
    },
    options,
  );
};
/**
 * Retrieve stripe products.
 * @summary Read All Stripe Subscriptions
 */
export const subscriptionsReadAllStripeSubscriptions = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeSubscription[]>(
    { url: `/api/v1/subscriptions/all`, method: "GET" },
    options,
  );
};
/**
 * Update a stripe product.
 * @summary Update Stripe Subscription
 */
export const subscriptionsUpdateStripeSubscription = (
  id: number,
  stripeSubscriptionUpdate: StripeSubscriptionUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeSubscription>(
    {
      url: `/api/v1/subscriptions/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: stripeSubscriptionUpdate,
    },
    options,
  );
};
/**
 * Delete a stripe product.
 * @summary Delete Stripe Subscription
 */
export const subscriptionsDeleteStripeSubscription = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeSubscription>(
    { url: `/api/v1/subscriptions/${id}`, method: "DELETE" },
    options,
  );
};
/**
 * @summary Stripe Webhook
 */
export const subscriptionsStripeWebhook = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeWebhookResponse>(
    { url: `/api/v1/subscriptions/stripe-webhook`, method: "POST" },
    options,
  );
};
/**
 * Create a customer portal session.
 * @summary Create Customer Portal Session
 */
export const subscriptionsCreateCustomerPortalSession = (
  createCustomerPortalSessionRequest: CreateCustomerPortalSessionRequest,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<CreateCustomerPortalSessionResponse>(
    {
      url: `/api/v1/subscriptions/create-customer-portal-session`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCustomerPortalSessionRequest,
    },
    options,
  );
};
export type SubscriptionsReadStripeSubscriptionsResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsReadStripeSubscriptions>>
>;
export type SubscriptionsCreateStripeSubscriptionResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsCreateStripeSubscription>>
>;
export type SubscriptionsReadAllStripeSubscriptionsResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsReadAllStripeSubscriptions>>
>;
export type SubscriptionsUpdateStripeSubscriptionResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsUpdateStripeSubscription>>
>;
export type SubscriptionsDeleteStripeSubscriptionResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsDeleteStripeSubscription>>
>;
export type SubscriptionsStripeWebhookResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsStripeWebhook>>
>;
export type SubscriptionsCreateCustomerPortalSessionResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionsCreateCustomerPortalSession>>
>;
