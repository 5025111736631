import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { slugify } from "@/utils";

export const getters = {
  adminUsers: (state: AdminState) => state.adminUsers,
  adminOneUser: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.adminUsers.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  adminSystems: (state: AdminState) => state.adminSystems,
  adminOneSystem: (state: AdminState) => (systemId: number) => {
    const filteredSystems = state.adminSystems.filter(
      (system) => system.id === systemId,
    );
    if (filteredSystems.length > 0) {
      return { ...filteredSystems[0] };
    }
  },
  adminSystemJobs: (state: AdminState) => state.adminSystemJobs,
  adminOneSystemJob: (state: AdminState) => (systemJobSlug: string) => {
    const filteredSystemJobs = state.adminSystemJobs.filter(
      (job) => slugify(job.long_name || "") === systemJobSlug,
    );
    if (filteredSystemJobs.length > 0) {
      return { ...filteredSystemJobs[0] };
    }
  },
  adminOneSystemJobFromId: (state: AdminState) => (systemJobId: number) => {
    const filteredSystemJobs = state.adminSystemJobs.filter(
      (job) => job.id === systemJobId,
    );
    if (filteredSystemJobs.length > 0) {
      return { ...filteredSystemJobs[0] };
    }
  },
  adminCurrentlyViewedSystemJob: (state: AdminState) =>
    state.adminCurrentlyViewedSystemJob,
  adminImageItems: (state: AdminState) => state.adminImageItems,
  adminImageItemCount: (state: AdminState) => state.adminImageItemCount,
  adminProducts: (state: AdminState) => state.adminStripeProducts,
  adminOneProduct: (state: AdminState) => (productId: number) => {
    const filteredProducts = state.adminStripeProducts.filter(
      (product) => product.id === productId,
    );
    if (filteredProducts.length > 0) {
      return { ...filteredProducts[0] };
    }
  },
  adminSubscriptions: (state: AdminState) => state.adminStripeSubscriptions,
  adminOneSubscription: (state: AdminState) => (subscriptionId: number) => {
    const filteredSubscriptions = state.adminStripeSubscriptions.filter(
      (subscription) => subscription.id === subscriptionId,
    );
    if (filteredSubscriptions.length > 0) {
      return { ...filteredSubscriptions[0] };
    }
  },
  adminPricings: (state: AdminState) => state.adminStripePricings,
  adminOnePricing: (state: AdminState) => (pricingId: number) => {
    const filteredPricings = state.adminStripePricings.filter(
      (pricing) => pricing.id === pricingId,
    );
    if (filteredPricings.length > 0) {
      return { ...filteredPricings[0] };
    }
  },
  adminAnnouncements: (state: AdminState) => state.adminAnnouncements,
  adminOneAnnouncement: (state: AdminState) => (announcementId: number) => {
    const filteredAnnouncements = state.adminAnnouncements.filter(
      (announcement) => announcement.id === announcementId,
    );
    if (filteredAnnouncements.length > 0) {
      return { ...filteredAnnouncements[0] };
    }
  },
};

const { read } = getStoreAccessors<AdminState, State>("");

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminSystems = read(getters.adminSystems);
export const readAdminOneSystem = read(getters.adminOneSystem);
export const readAdminSystemJobs = read(getters.adminSystemJobs);
export const readAdminOneSystemJob = read(getters.adminOneSystemJob);
export const readAdminOneSystemJobFromId = read(getters.adminOneSystemJobFromId);
export const readAdminCurrentlyViewedSystemJob = read(
  getters.adminCurrentlyViewedSystemJob,
);
export const readAdminImageItems = read(getters.adminImageItems);
export const readAdminImageItemCount = read(getters.adminImageItemCount);
export const readAdminOneProduct = read(getters.adminOneProduct);
export const readAdminProducts = read(getters.adminProducts);
export const readAdminSubscriptions = read(getters.adminSubscriptions);
export const readAdminOneSubscription = read(getters.adminOneSubscription);
export const readAdminPricings = read(getters.adminPricings);
export const readAdminOnePricing = read(getters.adminOnePricing);
export const readAdminAnnouncements = read(getters.adminAnnouncements);
export const readAdminOneAnnouncement = read(getters.adminOneAnnouncement);
