/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-12-04T17:33:00
 */
import type {
  Material,
  MaterialCreate,
  MaterialUpdate,
  MaterialsReadMaterialsParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Create new material.
 * @summary Create Material
 */
export const materialsCreateMaterial = (
  materialCreate: MaterialCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Material>(
    {
      url: `/api/v1/materials/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: materialCreate,
    },
    options,
  );
};
/**
 * Retrieve materials.
 * @summary Read Materials
 */
export const materialsReadMaterials = (
  params?: MaterialsReadMaterialsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Material[]>(
    { url: `/api/v1/materials/`, method: "GET", params },
    options,
  );
};
/**
 * Get material by ID.
 * @summary Read Material
 */
export const materialsReadMaterial = (
  materialId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Material>(
    { url: `/api/v1/materials/${materialId}`, method: "GET" },
    options,
  );
};
/**
 * Update an material.
 * @summary Update Material
 */
export const materialsUpdateMaterial = (
  materialId: number,
  materialUpdate: MaterialUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Material>(
    {
      url: `/api/v1/materials/${materialId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: materialUpdate,
    },
    options,
  );
};
/**
 * Delete a material.
 * @summary Delete Material
 */
export const materialsDeleteMaterial = (
  materialId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Material>(
    { url: `/api/v1/materials/${materialId}`, method: "DELETE" },
    options,
  );
};
export type MaterialsCreateMaterialResult = NonNullable<
  Awaited<ReturnType<typeof materialsCreateMaterial>>
>;
export type MaterialsReadMaterialsResult = NonNullable<
  Awaited<ReturnType<typeof materialsReadMaterials>>
>;
export type MaterialsReadMaterialResult = NonNullable<
  Awaited<ReturnType<typeof materialsReadMaterial>>
>;
export type MaterialsUpdateMaterialResult = NonNullable<
  Awaited<ReturnType<typeof materialsUpdateMaterial>>
>;
export type MaterialsDeleteMaterialResult = NonNullable<
  Awaited<ReturnType<typeof materialsDeleteMaterial>>
>;
