import { dispatchGetTaskStatus, dispatchGetSystemJobs } from "@/store/main/actions";
import {
  commitRemoveTask,
  commitRemoveTaskByID,
  commitAddAlert,
} from "@/store/main/mutations";
import { ITag } from "./interfaces";

/**
 * Retrieves the token from the browser's local storage.
 * @returns {string | null} The token if it exists, otherwise null.
 */
export const getLocalToken = (): string | null => localStorage.getItem("token");

/**
 * Saves a token to the browser's local storage.
 * @param {string} token - The token to be saved.
 */
export const saveLocalToken = (token: string): void =>
  localStorage.setItem("token", token);

/**
 * Removes the token from the browser's local storage.
 */
export const removeLocalToken = (): void => localStorage.removeItem("token");

/**
 * Converts a string into a URL-friendly slug.
 * This function replaces spaces with dashes, removes special characters, and normalizes the string.
 * @param {string} str - The input string to be slugified.
 * @returns {string} The slugified string.
 */
export function slugify(str?: string | null): string {
  const specialChars =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const replacementChars =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const regexPattern = new RegExp(specialChars.split("").join("|"), "g");

  return (str || "")
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(regexPattern, (c) => replacementChars.charAt(specialChars.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple dashes with a single dash
    .replace(/^-+/, "") // Trim dashes from the start of the string
    .replace(/-+$/, ""); // Trim dashes from the end of the string
}

/**
 * Converts a string to title case.
 * @param {string} str - The input string to be converted to title case.
 * @returns {string} The title cased string.
 */
export function toTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

/**
 * Converts a string to sentence case.
 * @param {string} str - The input string to be converted to sentence case.
 * @returns {string} The sentence cased string.
 */
export function toSentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Converts a snake_case string to a sentence case string.
 * @param {string} str - The input string to be converted to sentence case.
 * @returns {string} The sentence cased string.
 */
export function snakeToSentence(str: string): string {
  return str
    .split("_")
    .map((word) => toSentenceCase(word))
    .join(" ");
}

/**
 * Polls the task status from the server and performs corresponding actions based on the result.
 * Removes the task if it's completed successfully or failed and updates the store accordingly.
 * @param {any} store - The Vuex store instance.
 * @param {string} task_ids - The List of IDs of the task to poll.
 * @returns {Promise<boolean[]>} A promise that resolves to a boolean indicating whether the task completed successfully.
 */
export async function pollTaskStatus(
  store: any,
  task_ids: string[],
): Promise<boolean[] | undefined> {
  const payload = { task_ids: task_ids };
  const tasks = await dispatchGetTaskStatus(store, payload);
  const listOfSuccess: boolean[] = [];
  const taskStatusById = Object.fromEntries(
    tasks!.map((task) => [task["task_id"], task]),
  );
  for (const task_id of task_ids) {
    const task = taskStatusById[task_id];
    if (task == null || task == undefined) {
      // Task does not exist, remove it from the store by its ID
      commitRemoveTaskByID(store, task_id);
      listOfSuccess.push(true);
      continue;
    }

    let success = false;

    if (task["task_result"] === "success") {
      // Remove the completed task from the store
      commitRemoveTask(store, task);
      success = true;

      // Add a success alert to the store
      commitAddAlert(store, {
        content: `${task["task_status"]}`,
        color: "success",
      });

      // Handle specific task names and dispatch relevant actions
      if (task["task_name"] === "uploading_job_zip") {
        await dispatchGetSystemJobs(store);
      }
    } else if (task["task_result"] === "failed") {
      // Remove the failed task from the store
      commitRemoveTask(store, task);
      success = true;

      // Add an error alert to the store
      commitAddAlert(store, {
        content: `${task["task_status"]}`,
        color: "error",
      });
    }
    listOfSuccess.push(success);
  }
  return listOfSuccess;
}

/**
 * Determines the appropriate text color based on the tag color, selection status, and dark mode setting.
 * @param {ITag} tag - The tag object containing color information.
 * @param {boolean} selected - Whether the tag is selected.
 * @param {boolean} isDarkMode - Whether the dark mode is enabled.
 * @returns {string} The appropriate text color.
 */
export function getTextColor(
  tag: ITag,
  selected: boolean,
  isDarkMode: boolean,
): string {
  if (!selected) {
    if (isDarkMode) {
      return "#ffffff";
    } else {
      return "#000000";
    }
  }
  const colour = tag.colour || "#000000";
  const luminance = (hex: string) => {
    const a = [
      parseInt(hex.substr(1, 2), 16),
      parseInt(hex.substr(3, 2), 16),
      parseInt(hex.substr(5, 2), 16),
    ].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };
  return luminance(colour) > 0.5 ? "#000000" : "#ffffff";
}

/**
 * Converts a date string to a formatted time string.
 * @param {string | null} [time_data] - The date string to be converted.
 * @returns {string} The formatted time string.
 */
export function toTimeString(time_data?: string | null): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  if (!time_data) {
    return "";
  }
  const timeDataDate = new Date(time_data);
  const timeStr = timeDataDate.toLocaleString("en-GB", options);
  return timeStr;
}

/**
 * Converts a decimal number to a percentage string.
 * @param {number} decimal - The decimal number to be converted.
 * @returns {string} The percentage string.
 */
export function toPercentage(decimal: number): string {
  if (decimal) {
    const percentage = Math.round(decimal * 100);
    return percentage + "%";
  } else {
    return "";
  }
}
