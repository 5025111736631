import Vue from "vue";
import Router from "vue-router";

// Component Imports
import RouterComponent from "./components/RouterComponent.vue";

Vue.use(Router);

// Main Router Configuration
const router = new Router({
  mode: "history", // Use HTML5 history mode for cleaner URLs
  base: process.env.BASE_URL, // Base URL for the application
  routes: [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "start" */ "./views/main/Start.vue"),
      children: [
        // Authentication Routes
        {
          path: "login",
          component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        {
          path: "signup",
          component: () =>
            import(/* webpackChunkName: "signup" */ "./views/Signup.vue"),
        },
        {
          path: "recover-password",
          component: () =>
            import(
              /* webpackChunkName: "recover-password" */ "./views/PasswordRecovery.vue"
            ),
        },
        {
          path: "reset-password",
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"
            ),
        },

        // Main Application Routes
        {
          path: "main",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          children: [
            // Analytics Route
            {
              path: "analytics",
              component: () =>
                import(
                  /* webpackChunkName: "main-analytics" */ "./views/main/Analytics.vue"
                ),
            },

            // Settings Route with Optional Parameter
            {
              path: "settings/:categoryName?",
              component: () =>
                import(
                  /* webpackChunkName: "main-settings" */ "./views/main/Settings.vue"
                ),
              props: (route) => ({ categoryName: route.params.categoryName }),
            },

            // System Jobs Routes
            {
              path: "system-jobs",
              component: RouterComponent,
              redirect: "system-jobs/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-systemjobs" */ "./views/main/systemjobs/SystemJobs.vue"
                    ),
                },
                {
                  path: "job/view/:id/:slug",
                  name: "main-system-job-view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-one-systemjob" */ "./views/main/systemjobs/OneSystemJob.vue"
                    ),
                },
              ],
            },

            // Files Routes
            {
              path: "files",
              component: RouterComponent,
              redirect: "files/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-files" */ "./views/main/files/Files.vue"
                    ),
                },
                {
                  path: "file/view/:slug",
                  name: "main-file-view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-one-file" */ "./views/main/files/OneFile.vue"
                    ),
                },
              ],
            },

            // Systems Routes
            {
              path: "systems",
              component: RouterComponent,
              redirect: "systems/view",
              children: [
                {
                  path: "view",
                  name: "main-systems-view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-systems" */ "./views/main/systems/Systems.vue"
                    ),
                },
                {
                  path: "edit/:slug",
                  name: "main-system-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-System-edit" */ "./views/main/systems/EditSystem.vue"
                    ),
                },
                {
                  path: "view/:slug",
                  name: "main-system-view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-System-view" */ "./views/main/systems/OneSystem.vue"
                    ),
                },
                {
                  path: "create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-System-create" */ "./views/main/systems/CreateSystem.vue"
                    ),
                },
              ],
            },

            // Profile Routes
            {
              path: "profile",
              component: RouterComponent,
              redirect: "profile/view",
              children: [
                {
                  path: "edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ "./views/main/profile/UserProfileEdit.vue"
                    ),
                },
                {
                  path: "password",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ "./views/main/profile/UserProfileEditPassword.vue"
                    ),
                },
              ],
            },

            // Products Routes
            {
              path: "products",
              component: RouterComponent,
              redirect: "products/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-products" */ "./views/main/products/Products.vue"
                    ),
                },
              ],
            },

            // Admin Panel Routes
            {
              path: "admin",
              component: () =>
                import(
                  /* webpackChunkName: "main-admin" */ "./views/main/admin/Admin.vue"
                ),
              redirect: "admin/users/all",
              children: [
                // Admin Analytics
                {
                  path: "analytics",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-analytics" */ "./views/main/admin/AdminAnalytics.vue"
                    ),
                },

                // Admin Users Management
                {
                  path: "users",
                  redirect: "users/all",
                },
                {
                  path: "users/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ "./views/main/admin/AdminUsers.vue"
                    ),
                },
                {
                  path: "users/edit/:id",
                  name: "main-admin-users-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ "./views/main/admin/EditUser.vue"
                    ),
                },
                {
                  path: "users/create",
                  name: "main-admin-users-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ "./views/main/admin/CreateUser.vue"
                    ),
                },

                // Admin Products Management
                {
                  path: "products",
                  redirect: "products/all",
                },
                {
                  path: "products/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-products" */ "./views/main/admin/AdminProducts.vue"
                    ),
                },
                {
                  path: "products/edit/:id",
                  name: "main-admin-products-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-products-edit" */ "./views/main/admin/EditProduct.vue"
                    ),
                },
                {
                  path: "products/create",
                  name: "main-admin-products-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-products-create" */ "./views/main/admin/CreateProduct.vue"
                    ),
                },

                // Admin Pricings Management
                {
                  path: "pricings",
                  redirect: "pricings/all",
                },
                {
                  path: "pricings/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-pricings" */ "./views/main/admin/AdminPricings.vue"
                    ),
                },
                {
                  path: "pricings/edit/:id",
                  name: "main-admin-pricings-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-pricing-edit" */ "./views/main/admin/EditPricing.vue"
                    ),
                },
                {
                  path: "pricings/create",
                  name: "main-admin-pricings-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-pricing-create" */ "./views/main/admin/CreatePricing.vue"
                    ),
                },

                // Admin Subscriptions Management
                {
                  path: "subscriptions",
                  redirect: "subscriptions/all",
                },
                {
                  path: "subscriptions/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-subscriptions" */ "./views/main/admin/AdminSubscriptions.vue"
                    ),
                },
                {
                  path: "subscriptions/edit/:id",
                  name: "main-admin-subscriptions-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-subscriptions-edit" */ "./views/main/admin/EditSubscription.vue"
                    ),
                },

                // Admin Announcements Management
                {
                  path: "announcements",
                  redirect: "announcements/all",
                },
                {
                  path: "announcements/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-announcements" */ "./views/main/admin/AdminAnnouncements.vue"
                    ),
                },
                {
                  path: "announcements/edit/:id",
                  name: "main-admin-announcements-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-announcement-edit" */ "./views/main/admin/EditAnnouncement.vue"
                    ),
                },
                {
                  path: "announcements/create",
                  name: "main-admin-announcements-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-announcement-create" */ "./views/main/admin/CreateAnnouncement.vue"
                    ),
                },

                // Admin Systems Management
                {
                  path: "systems/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-systems" */ "./views/main/admin/AdminSystems.vue"
                    ),
                },

                // Admin Print Jobs Management
                {
                  path: "system-jobs/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-systemjobs" */ "./views/main/admin/AdminSystemJobs.vue"
                    ),
                },
                {
                  path: "system-jobs/job/view/:id/:slug",
                  name: "admin-system-job-view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-one-systemjob" */ "./views/main/admin/AdminOneSystemJob.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
