/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-12-04T17:33:00
 */
import type {
  AdminTableUser,
  BodyUsersCreateUserOpen,
  User,
  UserCreate,
  UserUpdate,
  UsersReadUsersParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve users.
 * @summary Read Users
 */
export const usersReadUsers = (
  params?: UsersReadUsersParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<AdminTableUser[]>(
    { url: `/api/v1/users/`, method: "GET", params },
    options,
  );
};
/**
 * Create new user.
 * @summary Create User
 */
export const usersCreateUser = (
  userCreate: UserCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<AdminTableUser>(
    {
      url: `/api/v1/users/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userCreate,
    },
    options,
  );
};
/**
 * Get current user.
 * @summary Read User Me
 */
export const usersReadUserMe = (options?: SecondParameter<typeof apiAxiosInstance>) => {
  return apiAxiosInstance<User>({ url: `/api/v1/users/me`, method: "GET" }, options);
};
/**
 * Update own user.
 * @summary Update User Me
 */
export const usersUpdateUserMe = (
  userUpdate: UserUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<User>(
    {
      url: `/api/v1/users/me`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: userUpdate,
    },
    options,
  );
};
/**
 * Create new user without the need to be logged in.
 * @summary Create User Open
 */
export const usersCreateUserOpen = (
  bodyUsersCreateUserOpen: BodyUsersCreateUserOpen,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<User>(
    {
      url: `/api/v1/users/open`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: bodyUsersCreateUserOpen,
    },
    options,
  );
};
/**
 * Get a specific user by id.
 * @summary Read User By Id
 */
export const usersReadUserById = (
  userId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<User>(
    { url: `/api/v1/users/${userId}`, method: "GET" },
    options,
  );
};
/**
 * Update a user.
 * @summary Update User
 */
export const usersUpdateUser = (
  userId: number,
  userUpdate: UserUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<AdminTableUser>(
    {
      url: `/api/v1/users/${userId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: userUpdate,
    },
    options,
  );
};
/**
 * Update a user.
 * @summary Delete User
 */
export const usersDeleteUser = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<User>(
    { url: `/api/v1/users/${id}`, method: "DELETE" },
    options,
  );
};
export type UsersReadUsersResult = NonNullable<
  Awaited<ReturnType<typeof usersReadUsers>>
>;
export type UsersCreateUserResult = NonNullable<
  Awaited<ReturnType<typeof usersCreateUser>>
>;
export type UsersReadUserMeResult = NonNullable<
  Awaited<ReturnType<typeof usersReadUserMe>>
>;
export type UsersUpdateUserMeResult = NonNullable<
  Awaited<ReturnType<typeof usersUpdateUserMe>>
>;
export type UsersCreateUserOpenResult = NonNullable<
  Awaited<ReturnType<typeof usersCreateUserOpen>>
>;
export type UsersReadUserByIdResult = NonNullable<
  Awaited<ReturnType<typeof usersReadUserById>>
>;
export type UsersUpdateUserResult = NonNullable<
  Awaited<ReturnType<typeof usersUpdateUser>>
>;
export type UsersDeleteUserResult = NonNullable<
  Awaited<ReturnType<typeof usersDeleteUser>>
>;
